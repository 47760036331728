const appConfig = {
  apiBaseUrl: "https://api.bracetekk.com/",
  apiPrefix: "/api",
  imageBaseUrl: "https://api.bracetekk.com/bucket/",
  authenticatedEntryPath: "/app/dashboard",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/app/account/kyc-form",
  enableMock: true,
};

export default appConfig;
